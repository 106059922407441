import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import LAY_EXCEL from 'lay-excel';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.css'
import api from './api/request'
import api3 from './api/request3'

import * as Echarts from 'echarts'
Vue.prototype.$echarts = Echarts
Vue.prototype.EventBus = new Vue();
Vue.prototype.$api = api
Vue.prototype.$api3 = api3
Vue.use(ElementUI);
Vue.prototype.$Excel = LAY_EXCEL

Vue.config.productionTip = false



// 路由守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化时，遍历修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  // if(to.path === '/login')
  // {
  //   return next()
  // }
  // const token = window.sessionStorage.getItem("token")
  // if(!token)
  // {
  //   return next('/login')
  // }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
